import { MessageTypes } from '../entities/MessageEntity.ts';

export const DAYS_PL = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];

export enum EVENT {
    'LONZA' = 1,
    'INDYWIDUALNA' = 2,
    'ZASTEP' = 3,
    'TEREN' = 4,
    'HIPOTERAPIA' = 5,
}

export const EVENT_NAME = {
    [EVENT.LONZA]: 'Lonża',
    [EVENT.INDYWIDUALNA]: 'Jazda indywidualna',
    [EVENT.ZASTEP]: 'Zastęp',
    [EVENT.TEREN]: 'Jazda terenowa',
    [EVENT.HIPOTERAPIA]: 'Hipoterapia',
};

export const GROUP_RIDINGS = [EVENT.ZASTEP, EVENT.TEREN];

export const EVENT_TYPES = [
    {
        title: EVENT_NAME[EVENT.LONZA],
        value: EVENT.LONZA,
    },
    {
        title: EVENT_NAME[EVENT.INDYWIDUALNA],
        value: EVENT.INDYWIDUALNA,
    },
    {
        title: EVENT_NAME[EVENT.ZASTEP],
        value: EVENT.ZASTEP,
    },
    {
        title: EVENT_NAME[EVENT.TEREN],
        value: EVENT.TEREN,
    },
    {
        title: EVENT_NAME[EVENT.HIPOTERAPIA],
        value: EVENT.HIPOTERAPIA,
    },
];

export const PERMISSION_SECTION_NAME = {
    users: 'Pracownicy',
    stable: 'Stajnia',
    horses: 'Konie',
    ridingSpots: 'Miejsca Jazdy',
    clients: 'Klienci',
    schedules: 'Wydarzenia',
    riding: 'Jazdy',
};

export const MESSAGE_TYPE_NAME: Record<MessageTypes, string> = {
    all: 'Wszystkie',
    app: 'Aplikacja',
    stable: 'Stajnia',
};
