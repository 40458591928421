import { DashboardOutlined } from '@ant-design/icons';
import { MailOutlined } from '@ant-design/icons';
import { MenuGroupType } from './types.ts';
import { __ } from '../misc/i18n.tsx';

const icons = {
    DashboardOutlined,
    MailOutlined,
};

const dashboard: MenuGroupType = {
    id: 'group-dashboard',
    title: 'Menu',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: __('Panel'),
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardOutlined,
            breadcrumbs: false,
        },
        {
            id: 'mail',
            title: 'Wiadomości',
            type: 'item',
            url: '/mail/all',
            icon: icons.MailOutlined,
            breadcrumbs: false,
        },
    ],
};

export default dashboard;
