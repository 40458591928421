import { useEffect, useState } from 'react';
import { marked } from 'marked';
import { gfmHeadingId } from 'marked-gfm-heading-id';
import { ChangeLogDataType, ChangesDataType, getRecentAppChanges } from './helper';
import { NotificationsListBoxStyles, NotificationsListStyles } from '../../../styles/notifications';
import { Box, Divider, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { IconCalendarDue } from '@tabler/icons-react';

const ChangelogList = () => {
    const CHANGES_TYPES: { [key: string]: string } = {
        'Bug Fixes': 'Poprawa błędów',
        Changes: 'Zmiany',
        Features: 'Nowości',
        Styles: 'Zmiany wizualne',
    };

    const [changeLogContent, setChangeLogContent] = useState<ChangeLogDataType>();

    const fetchChangeLog = async () => {
        fetch('/CHANGELOG.md')
            .then((res) => res.text())
            .then(async (text) => {
                const markedContent = await marked.use(gfmHeadingId())(text);
                const appChanges = getRecentAppChanges(markedContent);

                setChangeLogContent(appChanges);
            });
    };

    useEffect(() => {
        fetchChangeLog();
    }, []);

    const renderChanges = (changes: ChangesDataType) => {
        return changes.map((change) => {
            return (
                <Box key={change.type} sx={{ mt: 1, mb: 2 }}>
                    <Typography variant="h5" mb={1}>
                        {CHANGES_TYPES[change.type] || change.type}
                    </Typography>
                    <ul>
                        {change.changes.map((changeItem) => {
                            return <li key={changeItem}>{changeItem}</li>;
                        })}
                    </ul>
                </Box>
            );
        });
    };

    const renderVersions = () => {
        return changeLogContent?.map((version, index) => {
            return (
                <Box key={version.version} sx={{ my: 2, mx: 2 }}>
                    <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                            <Typography variant="h3" lineHeight={1}>
                                <span style={{ fontSize: 14 }}>v</span>
                                {version.version}
                                {index === 0 && '✨🎉'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <IconCalendarDue size={16} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1" lineHeight={1} fontWeight={400}>
                                        {dayjs(version.date).format('DD.MM.YYYY')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {renderChanges(version.changes)}

                    {index !== changeLogContent.length - 1 && <Divider />}
                </Box>
            );
        });
    };

    return (
        <NotificationsListStyles>
            <NotificationsListBoxStyles>{renderVersions()}</NotificationsListBoxStyles>
        </NotificationsListStyles>
    );
};

export default ChangelogList;
