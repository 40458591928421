import { Outlet } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store.ts';
import { Alert } from '@mui/lab';
import Header from './Header';
import { useEffect, useState } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';
import Drawer from './Drawer';
import { setDrawerOpenState } from '../../slices/config.slice.ts';
import IntroductionGame from './IntroductionGame/index.tsx';
import { useAppSelector } from '../../hooks.ts';
import { PERMISSION_NAMES } from '../../controllers/Permissions/consts.ts';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }: { theme: Theme; open: boolean }) => ({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...theme.typography.mainContent,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        ...(!open && {
            marginLeft: '20px',
        }),
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            padding: '16px',
            marginTop: 88,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginRight: '10px',
            padding: '16px',
            marginTop: 88,
        },
    }),
);

const Layout = () => {
    const drawerOpen = useSelector((state: RootState) => state.config.drawerOpen);
    const [open, setOpen] = useState(drawerOpen);
    const stable = useSelector((state: RootState) => state.stable.stable);
    const user = useAppSelector((state) => state.user.user);
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();

    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(setDrawerOpenState(!open));
    };

    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(setDrawerOpenState(!matchDownLG));
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
    }, [drawerOpen]);

    if (!stable) {
        return <div>Wczytujemy dane...</div>;
    }

    if (!stable.isActivated) {
        return (
            <Grid container>
                <Grid
                    xs={12}
                    style={{ height: '100vh' }}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Box>
                        <Alert style={{ width: '90%', margin: 'auto' }} severity="warning">
                            <strong>
                                Twoja stajnia nie została jeszcze zweryfikowana przez administrację aplikacji.
                            </strong>{' '}
                            Prosimy o cierpliwość. Weyfikacja nie powinna zająć dłużej 24 godziny.
                            <br />
                            Jeśli czas oczekiwania przedłuża się prosimy o kontakt: tel: <strong>
                                883 964 930
                            </strong>{' '}
                            lub mail: <strong>krzysztof.furtak@gmail.com</strong>
                        </Alert>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Main sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }} theme={theme} open={open}>
                {/*<Toolbar />*/}
                {/*<Breadcrumbs navigation={navigation} title />*/}
                <Outlet />
            </Main>

            {!stable.introductionCompleted && user?.role.includes(PERMISSION_NAMES.OWNER) ? <IntroductionGame /> : null}
        </Box>
    );

    // return (
    //     <div>
    //         <TopBar />
    //         <Container maxWidth="xl">{outlet}</Container>
    //     </div>
    // );
};

export default Layout;
