export const SUBSCRIPTION_FREE_PLAN = {
    horseLimit: 2,
    userLimit: 1,
};

export const SUBSCRIPTION_NOT_ACTIVE_STATUSES = ['future', 'paused', 'cancelled', 'transferred'];

export const RIDING_STATUS_NAMES = {
    done: 'Odbyła się',
    cancelled_client: 'Odwołana przez klienta',
    cancelled_stable: 'Odwołana przez stajnię',
};
